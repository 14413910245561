import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'src/store/enums';
import {IUserState} from "./user.types";
import {
  fetchAuthAction,
  fetchCreateDealerAction,
  fetchCreateUserAction,
  fetchDealersAction, fetchLogoutAction,
  fetchUserAction
} from "./user.actions";
import {decodeJwt} from "../../utils/functions";

const initialState: IUserState = {
  loading: false,
  user: {
    username: '',
    id: NaN,
    full_name: '',
    role: '',
    email: '',
  },
  access_token: '',
  err: ''
};

const slice = createSlice({
  initialState,
  name: SliceNames.USER,
  reducers: {
    setToken: (state, {payload}: {payload: string}) => {
      state.access_token = payload;
    },
    setUserId: (state, {payload}: {payload: number}) => {
      state.user.id = payload;
    },
    logout: (state) => {
      localStorage.clear();
      window.location.href = '/';
      state.user = initialState.user;
      state.access_token = '';
    },
    setOrderError: (state, {payload}: {payload: string}) => {
      state.err = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAuthAction.fulfilled,
        (state, { payload: {access} }) => {

          state.access_token = access;
          const decoded = decodeJwt(access);
          state.user.id = decoded.user_id;
          state.loading = false;
          state.err = '';
          return;
        },
      )
      .addCase(fetchAuthAction.pending, (state) => {
        state.loading = true;
        state.err = '';
      })
      .addCase(fetchAuthAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchUserAction.fulfilled,
        (state, { payload: user }) => {
          state.user = user;
          state.loading = false;
          state.err = '';
          return;
        },
      )
      .addCase(fetchUserAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(fetchUserAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchDealersAction.fulfilled,
        (state, { payload }) => {
          state.err = '';
          console.log(payload);
          return;
        },
      )
      .addCase(fetchDealersAction.pending, (state) => {
        state.loading = true;
        state.err = '';

      })
      .addCase(fetchDealersAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchCreateDealerAction.fulfilled,
        (state, { payload }) => {
          state.err = '';
          state.registerDataDealerId = payload.id;
          return;
        },
      )
      .addCase(fetchCreateDealerAction.pending, (state) => {
        state.loading = true;
        state.err = '';
      })
      .addCase(fetchCreateDealerAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchCreateUserAction.fulfilled,
        (state, { payload }) => {
          state.err = '';
          console.log(payload);
          state.registerDataDealerId = undefined;
          return;
        },
      )
      .addCase(fetchCreateUserAction.pending, (state) => {
        state.loading = true;
        state.err = '';
      })
      .addCase(fetchCreateUserAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
      .addCase(
        fetchLogoutAction.fulfilled,
        (state) => {
          state.err = '';
          localStorage.clear();
          window.location.href = '/';
          state.user = initialState.user;
          state.access_token = '';
          return;
        },
      )
      .addCase(fetchLogoutAction.pending, (state) => {
        state.loading = true;
        state.err = '';
        localStorage.clear();
        window.location.href = '/';
        state.user = initialState.user;
        state.access_token = '';
      })
      .addCase(fetchLogoutAction.rejected,
        (state, { error: { message = 'Что-то пошло не так, попробуйте позже' } }) => {
          state.err = message;
        },
      )
  },
});

export const { reducer: userReducer } = slice;
export const {setToken, setUserId, logout, setOrderError} = slice.actions;
