import React, {useEffect} from 'react';
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import {authorizedRoutes, publicRoutes} from './utils/routes';
import {AIS_Route, Auth_Route} from './utils/constants';
import {fetchOrdersAction, fetchStatusValuesAction} from "./store/order/order.actions";
import {useAppDispatch} from "./store/hooks";
import {useGetUser, useUserToken} from "./store/user/user.selectors";
import {setToken, setUserId} from "./store/user/user.slice";
import {decodeJwt} from "./utils/functions";
import {fetchUserAction} from "./store/user/user.actions";

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const token = useUserToken();
  const userId = useGetUser();
  const user = useGetUser();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(setToken(token))
    } else {
      history.push(Auth_Route);
    }
  }, []);

  useEffect(() => {
    if (token) {
      if (history.location.pathname.includes('auth')) {
        history.push(AIS_Route);
      }
      if (!userId.id) {
        const id = decodeJwt(token).user_id;
        dispatch(setUserId(id));
      }
      dispatch(fetchOrdersAction());
      dispatch(fetchStatusValuesAction())
    }
  },[token]);

  useEffect(() => {
    if (user.id && (!user.email && !user.role)) {
      dispatch(fetchUserAction({id: user.id}))
    }
  }, [user]);

  return (
    <Switch>
      {authorizedRoutes.map(({path, Component}) =>
        <Route key={path} path={path} component={Component} exact />
      )}
      {publicRoutes.map(({path, Component}) =>
        <Route key={path} path={path} component={Component} exact />
      )}
      <Redirect to={Auth_Route} />
    </Switch>
  );
};

export default AppRouter;
