import { createAsyncThunk } from '@reduxjs/toolkit';

import type {
  IConstruction,
  IOrder,
  IOrderWithConstructions,
  IReceivedConstructions
} from 'src/store/order/order.types';
import { SliceNames } from 'src/store/enums';
import type { ThunkAsyncConfig } from 'src/store/types';
import {EstimateItem, ICreateOrderReq} from "src/store/order/order.types";

const fetchOrdersAction = createAsyncThunk<
  { orders: IOrder[] },
  string | void,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/fetchOrdersAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { fetchOrdersService },
      },
    },
  ) => {
    try {
      const data = await fetchOrdersService(reqData);

      return {
        orders: data,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  },
);
const putOrderByIdAction = createAsyncThunk<
  IOrder,
  IOrder,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/putOrderByIdAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { putOrderByIdService },
      },
    },
  ) => {
    try {
      const data = await putOrderByIdService(reqData);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const putConstructionAction = createAsyncThunk<
  IReceivedConstructions,
  IReceivedConstructions,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/putConstructionAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { putConstructionService },
      },
    },
  ) => {
    try {
      const data = await putConstructionService(reqData);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const calculateConstructionAction = createAsyncThunk<
  IReceivedConstructions,
  IReceivedConstructions,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/calculateConstructionAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { calculateConstructionService },
      },
    },
  ) => {
    try {
      const data = await calculateConstructionService(reqData);
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      // @ts-ignore
      return Promise.reject(error);
    }
  },
);

const fetchEstimatesAction = createAsyncThunk<
  EstimateItem[],
  number,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/fetchEstimatesAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { fetchEstimatesService },
      },
    },
  ) => {
    try {
      const data = await fetchEstimatesService(reqData);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const deleteOrderAction = createAsyncThunk<
  number,
  number,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/deleteOrderAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { deleteOrderByIdService },
      },
    },
  ) => {
    try {
      const data = await deleteOrderByIdService(reqData);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const fetchOrderByIdAction = createAsyncThunk<
  IOrderWithConstructions,
  { orderId: number },
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/fetchOrderByIdAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { fetchOrderByIdService },
      },
    },
  ) => {
    try {
      const data = await fetchOrderByIdService(reqData);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const fetchConstructionsAction = createAsyncThunk<
  IReceivedConstructions[],
  number,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/getConstructionsAction`,
  async (
    orderId,
    {
      extra: {
        orderServices: { getConstructionsService },
      },
    },
  ) => {
    try {
      const data = await getConstructionsService(orderId);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const createConstructionAction = createAsyncThunk<
  IReceivedConstructions,
  IConstruction,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/createConstructionAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { createConstructionService },
      },
      dispatch
    },
  ) => {
    try {
      const data = await createConstructionService(reqData);
      console.log(data.id);
      if (data.id) {
        await dispatch(fetchEstimatesAction(data.id));
      }
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const fetchConstructionByIdAction = createAsyncThunk<
  IReceivedConstructions,
  { id: number },
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/fetchConstructionByIdAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { fetchConstructionByIdService },
      },
    },
  ) => {
    try {
      const data = await fetchConstructionByIdService(reqData);
      if (data.sashes) {
        if (data.parking.parking_right_place === 'Правая') {
          data.sashes?.sort((a, b) => b.sash_number - a.sash_number);
        } else {
          data.sashes?.sort((a, b) => a.sash_number - b.sash_number);
        }
      }
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const createOrderAction = createAsyncThunk<
  IOrder,
  ICreateOrderReq,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/createOrderAction`,
  async (
    reqData,
    {
      extra: {
        orderServices: { createOrderService },
      },
    },
  ) => {
    try {
      const data = await createOrderService(reqData);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const fetchStatusValuesAction = createAsyncThunk<
  { [key: string]: string },
  void,
  ThunkAsyncConfig
>(
  `${SliceNames.ORDERS}/fetchStatusValuesAction`,
  async (
    _,
    {
      extra: {
        orderServices: { fetchStatusValuesService },
      },
    },
  ) => {
    try {
      const data = await fetchStatusValuesService();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);


export {
  fetchOrdersAction,
  createOrderAction,
  putOrderByIdAction,
  fetchConstructionsAction,
  fetchOrderByIdAction,
  fetchConstructionByIdAction,
  createConstructionAction,
  putConstructionAction,
  deleteOrderAction,
  fetchStatusValuesAction,
  fetchEstimatesAction,
  calculateConstructionAction,
};
