import {axiosInstance as axios} from 'src/services/api';
import {IReceivedConstructions} from 'src/store/order/order.types';

const putConstruction = async (reqData: IReceivedConstructions): Promise<IReceivedConstructions> => {
  try {
    const {data} = await axios.put<IReceivedConstructions>(`/constructions/${reqData.id}/`, reqData);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { putConstruction };
