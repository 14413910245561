import React from 'react';

import './Footer.scss';
const Footer = () => {
  return (
    <div className={'footer'}>

    </div>
  );
};

export default Footer;
