import {axiosInstance as axios} from 'src/services/api';
import {IReceivedConstructions} from 'src/store/order/order.types';

const calculateConstruction = async (reqData: IReceivedConstructions): Promise<IReceivedConstructions> => {
  try {
    const {data} = await axios.post<IReceivedConstructions>(`/constructions/${reqData.id}/f4_recalculation/`, reqData);
    console.log(data);
    return data;
  } catch (e) {
    console.log(JSON.parse(JSON.stringify(e)));
    // @ts-ignore
    console.log(e.response);
    // @ts-ignore
    return Promise.reject(e.response);
  }
};

export { calculateConstruction };
