import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceNames } from 'src/store/enums';
import type { ThunkAsyncConfig } from 'src/store/types';
import {
  IAuthReqData,
  IAuthResData,
  IDealerCreate,
  IUserCreate,
  IUserGetReqData,
  IUserGetResData
} from "./user.types";

const fetchAuthAction = createAsyncThunk<
  IAuthResData,
  IAuthReqData,
  ThunkAsyncConfig
>(
  `${SliceNames.USER}/fetchAuthAction`,
  async (
    reqData,
    {
      extra: {
        userServices: { fetchAuthService },
      },
    },
  ) => {
    try {
      const {data} = await fetchAuthService(reqData);
      localStorage.setItem('access_token', data.access);
      localStorage.setItem('refresh_token', data.refresh);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const fetchLogoutAction = createAsyncThunk<
  void,
  void,
  ThunkAsyncConfig
>(
  `${SliceNames.USER}/fetchLogoutAction`,
  async (
    _,
    {
      extra: {
        userServices: { logoutUserService },
      },
    },
  ) => {
    try {
      const refresh_token =localStorage.getItem('refresh_token');
      const {data} = await logoutUserService({refresh: refresh_token ? refresh_token : ''});
      console.log(data)
      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const fetchUserAction = createAsyncThunk<
  IUserGetResData,
  IUserGetReqData,
  ThunkAsyncConfig
>(
  `${SliceNames.USER}/fetchUserAction`,
  async (
    reqData,
    {
      extra: {
        userServices: { fetchUserService },
      },
    },
  ) => {
    try {
      const {data} = await fetchUserService(reqData);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const fetchDealersAction = createAsyncThunk<
  any,
  void,
  ThunkAsyncConfig
>(
  `${SliceNames.USER}/fetchDealersAction`,
  async (
    _,
    {
      extra: {
        userServices: { fetchDealersService },
      },
    },
  ) => {
    try {
      const {data} = await fetchDealersService();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const fetchCreateUserAction = createAsyncThunk<
  any,
  IUserCreate,
  ThunkAsyncConfig
>(
  `${SliceNames.USER}/fetchCreateUserAction`,
  async (
    reqData,
    {
      extra: {
        userServices: { fetchCreateUserService },
      },
      dispatch
    },
  ) => {
    try {
      const data = await fetchCreateUserService(reqData);
      if (data?.username) {
        await dispatch(fetchAuthAction({
          username: data.username,
          password: data.password,
        }))
      }
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

const fetchCreateDealerAction = createAsyncThunk<
  any,
  IDealerCreate,
  ThunkAsyncConfig
>(
  `${SliceNames.USER}/fetchCreateDealerAction`,
  async (
    reqData,
    {
      extra: {
        userServices: { fetchCreateDealerService },
      },
    },
  ) => {
    try {
      const {data} = await fetchCreateDealerService(reqData);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export {
  fetchAuthAction,
  fetchUserAction,
  fetchDealersAction,
  fetchCreateUserAction,
  fetchCreateDealerAction,
  fetchLogoutAction,
};
