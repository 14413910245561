import {axiosInstance as axios} from 'src/services/api';

const deleteOrderById = async (reqData: number): Promise<number> => {
  try {
    await axios.delete<void>(`/orders/${reqData}/`);
    return reqData;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { deleteOrderById };
