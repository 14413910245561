import {axiosInstance as axios} from 'src/services/api';
import {IOrder} from 'src/store/order/order.types';

const fetchOrders = async (reqData: string | void): Promise<IOrder[]> => {
  try {
    const { data } = await axios.get<IOrder[]>(`orders/${reqData ? `?ordering=${reqData}` : ''}`);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { fetchOrders };
