import {axiosInstance as axios} from 'src/services/api';
import {IConstruction, IReceivedConstructions} from 'src/store/order/order.types';

const fetchCreateConstruction = async (reqData: IConstruction): Promise<IReceivedConstructions> => {
  try {
    const {data} = await axios.post<IReceivedConstructions>('/constructions/', reqData);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { fetchCreateConstruction };
