import React, {useEffect, useState} from 'react';
import {
  useGetConstructions,
  useGetCurrOrder,
  useOrdersError,
  useStatusLabelForSelector
} from "../../store/order/order.selectors";
import {useHistory, useParams} from "react-router-dom";
import {
  AIS_Route,
  Order_Construction
} from "../../utils/constants";
import {useAppDispatch} from "../../store/hooks";
import {
  deleteOrderAction,
  fetchOrderByIdAction,
  putOrderByIdAction
} from "../../store/order/order.actions";
import {Button} from "primereact/button";
import {RenderFormFields} from "../components/RenderForm/RenderFormFields";
import {orderFormFields} from "../../utils/inputFields";
import {IOrder} from "../../store/order/order.types";

import './OrderPage.scss';
import {setOpenedOrder} from "../../store/order/order.slice";
import {Dropdown} from "primereact/dropdown";
import {formatDate} from "../../utils/functions";
import {useUserRole} from "../../store/user/user.selectors";
import * as process from "process";

const initState:IOrder = {
  id: 0,
  dealer: '',
  date_create: '',
  status: '',
  customer: '',
  order_number: '',
}

const OrderPage = () => {
  const dispatch = useAppDispatch();
  const params = useParams<any>();

  const currOrder = useGetCurrOrder(params.orderId);
  const err = useOrdersError();
  const [order, setOrder] = useState<IOrder>(initState);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const constructions = useGetConstructions();
  const statuses = useStatusLabelForSelector()
  const history = useHistory();

  const userRole = useUserRole()

  useEffect(() => {
    if (!params.orderId) {//По логике orderId будет в любом случае
      history.push(AIS_Route);
      return
    }
    dispatch(fetchOrderByIdAction({orderId: params.orderId}));
  }, []);

  useEffect(() => {
    if (err) {
      history.push(AIS_Route);
    }
  }, [err]);

  const newConstructionClick = () => {
    history.push(Order_Construction.replace(':id', 'new').replace(':orderId', params.orderId));
  }

  useEffect(() => {
    if (currOrder?.id) {
      const middlewareOrder = {...currOrder, date_create: formatDate(currOrder.date_create)}
      setOrder(middlewareOrder);
    } else if (!params.orderId) {
      history.push(AIS_Route);
    }
  }, [currOrder]);

  const handleChange = (e: any) => {
    setIsEdited(true);
    const {name, value} = e.target;
    setOrder((prevState) => {
      return {...prevState, [name]: value}
    })
  }

  const saveOrder = () => {
    dispatch(putOrderByIdAction(order));
    setIsEdited(false);
  }

  const deleteOrder = async () => {
    history.push(AIS_Route);
    dispatch(setOpenedOrder(-1));
    dispatch(deleteOrderAction(order.id));
  }

  const disabled = userRole === 'administrator' || userRole === 'production_employee';

  return (
    <>
      <div className={'order-page__container'}>
        <div className={'order-page__basic-form-container'}>
          <RenderFormFields disabled={disabled} array={orderFormFields} formObject={order} handleChange={handleChange} />
          <Dropdown
            value={order.status}
            onChange={handleChange}
            options={statuses}
            disabled={disabled}
            name={'status'}
            placeholder="Статус заявки"
            className="w-full md:w-14rem"
          />
        </div>
        {isEdited ? (
          <Button onClick={saveOrder} disabled={disabled} className={'order-page__order-button'}>Сохранить</Button>
        ) : null}

        {disabled ? null : (
          <Button
            icon={'pi pi-trash'}
            onClick={deleteOrder}
            severity={'danger'}
            className={'order-page__order-button'}>
            Удалить
          </Button>
        )}

        <a
          className={'p-button order-page__download-button'}
          href={`${process.env.REACT_APP_BASE_URL}/estimate_export_order/${order.id}/`}>
          Скачать смету
        </a>
      </div>
      <div>
        <div className={'order-page__construction-title'}>
          Конструкции:
        </div>
        <div className={'order-page__construction-container'}>
          <div className={'order-page__construction-item'}>
            <Button
              className={'order-page__construction-button'}
              size="large"
              outlined
              severity="secondary"
              disabled={disabled}
              onClick={newConstructionClick}
            >
              F4
            </Button>
          </div>
          {
            constructions && constructions.length
              ? (
                constructions.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={'order-page__construction-item'}
                      onClick={() => history.push(Order_Construction.replace(':id', `${item.id}`).replace(':orderId', params.orderId))}>
                      <div>ID конструкции: {item.id}</div>
                      <div>Количество створок: {item.sashes_count}</div>
                      <div>Тип конструкции: {item.construction_type}</div>
                      <div>Высота конструкции: {item.height}</div>
                      <div>Ширина конструкции: {item.width}</div>
                    </div>
                  )
                })
              )
              : null
          }
        </div>
      </div>
    </>
  );
};

export default OrderPage;
