import React from 'react';
import './EstimateTable.scss';
import {EstimateItem} from "../../../store/order/order.types";
import {Button} from "primereact/button";

interface EstimateTableProps {
  items: EstimateItem[];
  id: string;
  recalculateEstimate: () => void
}

const EstimateTable: React.FC<EstimateTableProps> = ({ items, recalculateEstimate, id }) => {
  return (
    <>
      <Button className={'estimate-table-wrapper__button'} onClick={recalculateEstimate}>
        Пересчитать смету
      </Button>
      <a
        className={'p-button estimate-table-wrapper__download-button'}
        href={`${process.env.REACT_APP_BASE_URL}/estimate_export_construction/${id}/`}>
        Скачать
      </a>
      <div className="estimate-table-wrapper">

        <table className="estimate-table">
          <thead>
            <tr>
              {/*<th>Construction</th>*/}
              <th>Элемент</th>
              <th>Количество</th>
              <th>Размер</th>
              <th>Цена</th>
              <th>Сумма</th>
              <th>Примечание</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                {/*<td>{item.construction}</td>*/}
                <td>{item.item || '-'}</td>
                <td>{item.item_count}</td>
                <td>{item.item_size || '-'}</td>
                <td>{item.item_price}</td>
                <td>{item.item_sum || '-'}</td>
                <td>{item.note || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EstimateTable;
