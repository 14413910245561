import AuthPage from '../pages/Auth/AuthPage';
import AISModule from '../pages/AISModule/AISModule';

import {
  Auth_Route,
  AIS_Route,
  Order,
  Order_Construction,
  User_Route,
} from './constants';
import OrderPage from "../pages/OrderPage/OrderPage";
import ConstructionPage from "../pages/ConstructioinPage/ConstructionPage";
import UserPage from "../pages/UserPage/UserPage";

export const publicRoutes = [
  {
    path: Auth_Route,
    Component: AuthPage
  },
];

export const authorizedRoutes = [
  {
    path: AIS_Route,
    Component: AISModule
  },
  {
    path: User_Route,
    Component: UserPage
  },
  {
    path: Order,
    Component: OrderPage
  },
  {
    path: Order_Construction,
    Component: ConstructionPage
  },
];
