import {FloatLabel} from "primereact/floatlabel";
import {InputText} from "primereact/inputtext";
import React from "react";
import {IFormTextItem} from "../../ConstructioinPage/ConstructionPage";

export const RenderFormFields = (
  {
    array,
    formObject,
    handleChange,
    disabled,
  }:{
    array: IFormTextItem[],
    formObject: any,
    handleChange: (e: any) => void,
    disabled?: boolean;
  }) => {
  return (
    <>
      {
        array.map(field => {
          const value = formObject[field.id]
          || field.object
            ? field.object
              ? formObject[field.object][field.id]
              : formObject[field.id]
            : '';
          return (
            <FloatLabel key={field.id}>
              <InputText
                className={'input'}
                id={field.id}
                name={field.id}
                type={field.type}
                disabled={field.disabled ? field.disabled : disabled}
                value={value}
                onChange={handleChange}
              />
              <label htmlFor={field.id}>{field.label}</label>
            </FloatLabel>
          )
        })
      }
    </>
  )
};
