import React, {useEffect, useState} from 'react';
import PageTitle from "../pageTitle/PageTitle";
import {RenderFormFields} from "../RenderForm/RenderFormFields";
import {
  firstGroupFields,
  firstGroupFieldsSashes1,
  firstGroupFieldsSashes1Disabled,
  radioAssFields,
  radioColorFields,
  radioLeftParkFieldsPlace,
  radioLeftParkFieldsType,
  radioRightParkFieldsPlace,
  radioRightParkFieldsType
} from "../../../utils/inputFields";
import {RenderRadioGroup} from "../RenderForm/RenderRadioGroup";
import {IReceivedConstructions} from "../../../store/order/order.types";
import {Slider} from "primereact/slider";
// import {Checkbox} from "primereact/checkbox";

const ConstructionForm = ({
  formObject,
  handleChange,
  handleChangePark,
  handleChangeParkSashCount,
  handleChangeAssembly,
  handleChangeColor,
  isNew,
  disabled
}: {
  formObject: IReceivedConstructions,
  handleChange: (data: any) => void,
  handleChangePark: (data: any) => void,
  handleChangeParkSashCount: (data: any) => void,
  handleChangeProfiles: (data: any) => void,
  handleChangeAssembly: (data: any) => void,
  handleChangeColor: (data: any) => void,
  enabledButton: (data: any) => boolean,
  isEdited: boolean,
  isNew: boolean,
  disabled?: boolean,
  createConstruction: () => void
}) => {

  const [slider, setSlider] = useState<any>(0);

  const left = formObject.parking.parking_left_sashes_count ? formObject.parking.parking_left_sashes_count : 0
  const right = formObject.parking.parking_right_sashes_count ? formObject.parking.parking_right_sashes_count : 0
  const maxValueSlider = parseInt(`${left + right}`)
  useEffect(() => {
    if (maxValueSlider) {
      setSlider(left);
    }
  }, [maxValueSlider, formObject.parking.parking_left_sashes_count]);

  return (
    <div>
      <PageTitle title={'Конструкция'} />
      <>
        <div className={'construction__form'}>
          <div className={'construction__container'}>
            <div className={'construction__basic-form-container'}>
              <RenderFormFields
                disabled={disabled}
                array={firstGroupFields}
                formObject={formObject}
                handleChange={handleChange}
              />
              {
                isNew ? (
                  <RenderFormFields
                    disabled={disabled}
                    array={firstGroupFieldsSashes1}
                    formObject={formObject}
                    handleChange={handleChangePark}
                  />
                ) : (
                  <RenderFormFields
                    disabled={disabled}
                    array={firstGroupFieldsSashes1Disabled}
                    formObject={formObject}
                    handleChange={handleChangePark}
                  />
                )
              }
              {
                formObject.parking.parking_left_type && formObject.parking.parking_right_type ? (
                  <Slider disabled={disabled} min={0} name={'slider'} max={maxValueSlider} value={slider} onChange={(e) => {
                    // @ts-ignore
                    if (e.value <= 12 && (maxValueSlider - e.value) <= 12) {
                      handleChangeParkSashCount(e);
                    }
                  }
                  } />
                ) : null
              }

            </div>
            <div className={'create-order__radio-forms'}>
              <div className={'construction__radio-forms-row'}>
                <div className={'construction__park-variant'}>
                  Левая парковка
                  <div className={'construction__park-variant_form'}>
                    <RenderRadioGroup
                      handleChange={handleChangePark}
                      disabled={disabled}
                      formObject={formObject}
                      fields={radioLeftParkFieldsType}
                    />
                    {
                      formObject.parking.parking_left_type ? (
                        <RenderRadioGroup
                          handleChange={handleChangePark}
                          formObject={formObject}
                          disabled={disabled}
                          fields={radioLeftParkFieldsPlace}
                        />
                      ) : null
                    }
                  </div>

                </div>
                <div className={'construction__park-variant'}>
                  Правая парковка
                  <div className={'construction__park-variant_form'}>
                    <RenderRadioGroup
                      handleChange={handleChangePark}
                      formObject={formObject}
                      disabled={disabled}
                      fields={radioRightParkFieldsType}
                    />
                    {
                      formObject.parking.parking_right_type ? (
                        <RenderRadioGroup
                          handleChange={handleChangePark}
                          formObject={formObject}
                          disabled={disabled}
                          fields={radioRightParkFieldsPlace}
                        />
                      ) : null
                    }

                  </div>

                </div>
              </div>

              {/*<div className={'construction__checkbox-container'}>*/}
              {/*  <div>*/}
              {/*    Две парковки*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className={'construction__profile-variant'}>
                <RenderRadioGroup
                  handleChange={handleChangeAssembly}
                  formObject={formObject}
                  disabled={disabled}
                  fields={radioAssFields}
                />
              </div>
              <div className={'construction__profile-variant'}>
                <RenderRadioGroup
                  handleChange={handleChangeColor}
                  formObject={formObject}
                  disabled={disabled}
                  fields={radioColorFields}
                />
              </div>
            </div>

          </div>
        </div>
      </>
      <div className={'construction__button-container'}>

        {/*{*/}
        {/*  formObject.sashes*/}
        {/*    ? (*/}
        {/*      <Button*/}
        {/*        className={'construction__button'}*/}
        {/*        icon={'pi pi-arrow-right'}*/}
        {/*        iconPos={'right'}*/}
        {/*        disabled={!enabledButton(formObject) || isEdited}*/}
        {/*        onClick={goToSashes}>*/}
        {/*        Створки*/}
        {/*      </Button>*/}
        {/*    )*/}
        {/*    : null*/}
        {/*}*/}
      </div>
    </div>
  );
};

export default ConstructionForm;
