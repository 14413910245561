import {axiosInstance as axios} from 'src/services/api';
import {EstimateItem} from "../../store/order/order.types";

const fetchEstimates = async ({constructionId}: {constructionId: number}): Promise<EstimateItem[]> => {
  console.log('aaaaasdfasfjnnas dfknasjkdnaskj djkas djkas ');
  try {
    const {data} = await axios.get<EstimateItem[]>(`/estimate_lines/${constructionId}/`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { fetchEstimates };
