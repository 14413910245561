import React, {useEffect, useRef} from 'react';

import logoImage from './assets/images/logo.png';

import './Header.scss';
import {useHistory} from "react-router-dom";
import {AIS_Route, User_Route} from "../../../utils/constants";
import {useAppDispatch} from "../../../store/hooks";
import {setOpenedOrder} from "../../../store/order/order.slice";
import {Toast} from "primereact/toast";
import {useOrdersError} from "../../../store/order/order.selectors";
import {useUserError} from "../../../store/user/user.selectors";
import {Button} from "primereact/button";

export interface IShowError {
  messageContent: string
}

const Header = () => {
  const history = useHistory();
  const toast = useRef<any>(null);
  const dispatch = useAppDispatch();

  const orderErr = useOrdersError();
  const userErr = useUserError();

  const click = () => {
    history.push(AIS_Route);
    dispatch(setOpenedOrder(-1))
  }

  const goToUserPage = () => {
    history.push(User_Route);
  }

  const showError = ({messageContent}: IShowError) => {
    if (toast.current) {
      toast.current.show({severity:'error', summary: 'Ошибка', detail: messageContent, life: 3000});
    }
  }

  useEffect(() => {
    if (typeof orderErr === 'string' && orderErr.length > 1) {
      showError({messageContent: orderErr})

    }
  }, [orderErr]);

  useEffect(() => {
    if (typeof userErr === 'string' && userErr.length > 1) {
      showError({messageContent: userErr})
    }
  }, [userErr]);

  return (
    <div className={'header'}>
      <img onClick={click} alt={''} className={'header__logo'} src={logoImage}/>
      <Button icon={'pi pi-user'} onClick={goToUserPage}></Button>
      <Toast ref={toast} />
    </div>
  );
};

export default Header;
