import {RadioButton} from "primereact/radiobutton";
import React from "react";
import {IFormRadioItem} from "../../ConstructioinPage/ConstructionPage";

export const RenderRadioGroup = (
  {
    handleChange,
    formObject,
    fields,
    disabled,
  }:{
    handleChange: (e: any) => void,
    formObject: any,
    fields: IFormRadioItem[],
    disabled?: boolean
  }) => {
  return (
    <>
      {
        fields.map((item) => {
          return (
            <div className={'construction__radio-group'} key={item.id}>
              <div className={'construction__radio-group-title'}>
                {item.name}
              </div>
              {Object.entries(item.variants).map((variantItem,indexVariant) => {
                return (
                  <div className='flex align-items-center' key={`${item.id}${indexVariant}`}>
                    <RadioButton
                      inputId={`${variantItem[1]}${item.id}${indexVariant}`}
                      name={item.id}
                      value={variantItem[1]}
                      disabled={disabled}
                      onChange={handleChange}
                      checked={
                        item.type
                          ? formObject[item.type][item.id] === variantItem[1]
                          : formObject[item.id] === variantItem[1]
                      }
                    />
                    <label htmlFor={`${variantItem[1]}${item.id}${indexVariant}`} className='ml-2'>{variantItem[0]}</label>
                  </div>
                )
              })}
            </div>
          )
        })
      }
    </>
  )
}
