import {axiosInstance as axios} from 'src/services/api';
import {IReceivedConstructions} from 'src/store/order/order.types';


const getConstructions = async ({orderId}: {orderId: number}): Promise<IReceivedConstructions[]> => {
  try {
    const { data } = await axios.get<IReceivedConstructions[]>('', {params: orderId});

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getConstructions };
