import {axiosInstance as axios} from 'src/services/api';
import {ICreateOrderReq, IOrder} from 'src/store/order/order.types';

const fetchCreateOrder = async (reqData: ICreateOrderReq): Promise<IOrder> => {
  try {
    const {data} = await axios.post<IOrder>('/orders/', reqData);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { fetchCreateOrder };
