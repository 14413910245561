import React from 'react';
import {useGetUser} from "../../store/user/user.selectors";
import './UserPage.scss';
import {useAppDispatch} from "../../store/hooks";
// import {logout} from "../../store/user/user.slice";
import {Button} from "primereact/button";
import {fetchLogoutAction} from "../../store/user/user.actions";

const UserPage = () => {
  const dispatch = useAppDispatch();

  const { username, email, full_name, role } = useGetUser();

  const onLogout = () => {
    dispatch(fetchLogoutAction());
  };

  return (
    <div className="user-profile">
      <h1 className="user-profile__title">User Profile</h1>
      <div className="user-profile__info">
        <div className="user-profile__info-item">
          <span className="user-profile__label">Username:</span>
          <span className="user-profile__value">{username}</span>
        </div>
        <div className="user-profile__info-item">
          <span className="user-profile__label">Email:</span>
          <span className="user-profile__value">{email}</span>
        </div>
        <div className="user-profile__info-item">
          <span className="user-profile__label">Full Name:</span>
          <span className="user-profile__value">{full_name}</span>
        </div>
        <div className="user-profile__info-item">
          <span className="user-profile__label">Role:</span>
          <span className="user-profile__value">{role}</span>
        </div>
      </div>
      <Button
        label="Выйти"
        icon="pi pi-sign-out"
        className="user-profile__logout-button p-button-danger"
        onClick={onLogout}
      />
    </div>
  );
};

export default UserPage;
