import React from 'react';

import './PageTitle.scss';

const PageTitle = ({title}: {title: string}) => {
  return (
    <div className={'page-title'}>
      {title}
    </div>
  );
};

export default PageTitle;
