import {axiosInstance as axios} from 'src/services/api';
import {IOrder} from 'src/store/order/order.types';

const putCreateOrderById = async (reqData: IOrder): Promise<IOrder> => {
  try {
    const {data} = await axios.put<IOrder>(`/orders/${reqData.id}/`, reqData);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { putCreateOrderById };
