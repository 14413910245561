import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "primereact/breadcrumb";
import {MenuItem} from "primereact/menuitem";
import {useLocation} from "react-router-dom";
import {AIS_Route} from "../../../utils/constants";
import {useUserToken} from "../../../store/user/user.selectors";

const BreadCrumbsComponent = () => {
  const location = useLocation();
  const token = useUserToken();
  const [items, setItems] = useState<MenuItem[]>([]);

  const obj: Record<string, string> = {
    'auth': 'Авторизация',
    'ais-module': 'Главная',
    'order-page': 'Заказ №#',
    'construction': 'Конструкция №#',
  };

  useEffect(() => {
    const pathNames = location.pathname.split('/').filter(x => x);
    const breadcrumbItems = pathNames.reduce((acc, value, index) => {
      const url = `/${pathNames.slice(0, index + 1).join('/')}`;
      if (!isNaN(parseInt(value))) {
        if (acc.length > 0) {
          acc[acc.length - 1].label = acc[acc.length - 1].label?.replace('#', value);
          acc[acc.length - 1].url = acc[acc.length - 1].url?.concat(`/${value}`);
        }
      } else if (value === 'new') {
        if (acc.length > 0) {
          acc[acc.length - 1].label = acc[acc.length - 1].label?.replace('№#', ' новая');
        }
      } else {
        acc.push({ label: obj[value] || value, url: url });
      }
      return acc;
    }, [] as MenuItem[]);
    setItems(breadcrumbItems);
  }, [location]);

  return (
    <div>
      <BreadCrumb model={items} home={token ? {url: AIS_Route, icon: 'pi pi-home'} : undefined} />
    </div>
  );
};

export default BreadCrumbsComponent;
