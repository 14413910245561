import {fetchAuth} from "./authService";
import {fetchUser} from "./fetchUser";
import {IAuthReqData, IDealerCreate, ILogoutReqData, IUserCreate, IUserGetReqData} from "../../store/user/user.types";
import {getDealers} from "./getDealers";
import {createUser} from "./createUser";
import {createDealer} from "./createDealer";
import {logoutUser} from "./logoutUser";

class UserServices {
  fetchAuthService = (reqData: IAuthReqData) => fetchAuth(reqData);
  logoutUserService = (reqData: ILogoutReqData) => logoutUser(reqData);
  fetchUserService = (reqData: IUserGetReqData) => fetchUser(reqData);
  fetchDealersService = () => getDealers();
  fetchCreateUserService = (reqData: IUserCreate) => createUser(reqData);
  fetchCreateDealerService = (reqData: IDealerCreate) => createDealer(reqData);
}

const userServices = new UserServices();

export { userServices };
