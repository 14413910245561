import {axiosInstance as axios} from 'src/services/api';
import {IOrderWithConstructions} from 'src/store/order/order.types';

const fetchOrderById = async ({orderId}: {orderId: number}): Promise<IOrderWithConstructions> => {
  try {
    const { data } = await axios.get<IOrderWithConstructions>(`/orders/${orderId}/`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { fetchOrderById };
