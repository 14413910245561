import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';

import { orderServices } from 'src/services/orders';
import type {
  Dependencies,
  Middlewares,
  Reducers,
  RootState,
} from './types';
import {orderReducer} from './order/order.slice';
import {userServices} from "../services/user";
import {userReducer} from "./user/user.slice";


const dependencies: Dependencies = {
  orderServices: orderServices,
  userServices: userServices,
};

const reducers: Reducers = {
  orders: orderReducer,
  user: userReducer,
};


const rootReducer = combineReducers(reducers);


const store = configureStore<RootState, AnyAction, Middlewares>({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: dependencies,
      },
    }),
});


export { store };

export type AppDispatch = typeof store.dispatch
