import React, {useEffect, useState} from 'react';

import {useHistory, useParams} from "react-router-dom";

import {useAppDispatch} from "../../store/hooks";
import {
  calculateConstructionAction,
  createConstructionAction,
  fetchConstructionByIdAction, fetchEstimatesAction, putConstructionAction
} from "../../store/order/order.actions";
import {
  IConstruction,
  IReceivedConstructions, ISash
} from "../../store/order/order.types";
import {
  useConstructionEstamate,
  useGetOpenedConstruction,
  useGetOpenedConstructionId
} from "../../store/order/order.selectors";


import './ConstructionPage.scss';
import './slide.scss';
import ConstructionForm from "../components/ConstructionForm/ConstructionForm";
import GraphicForm from "../components/GraphicForm/GraphicForm";
import {Order_Construction} from "../../utils/constants";
import EstimateTable from "../components/estimateTable/EstimateTable";
import {useUserRole} from "../../store/user/user.selectors";
import {Button} from "primereact/button";


export interface IFormTextItem { id: string, label: string, type: string, disabled?: boolean, object?: string }
export interface IFormRadioItem { id: string, name: string, type: keyof IConstruction | string, variants: {[key: string]: string | null} }

const ConstructionPage = () => {
  const params = useParams<{id: string, orderId: string}>();
  const history = useHistory();

  const userRole = useUserRole();

  const disabled = userRole == 'administrator' || userRole === 'production_employee';

  const initStatePage: IReceivedConstructions = {
    id: NaN,
    order: parseInt(params.orderId),
    construction_type: 'F4',
    amount: NaN,
    assembly: '',
    painting: '',
    parking: {
      parking_left_type: '',
      parking_left_place: '',
      parking_right_type: '',
      parking_right_place: '',
      parking_left_sashes_count: null,
      parking_right_sashes_count: null,
    },
    width: NaN,
    height: NaN,
    profiles: {
      profile_start_type: '',
      profile_end_type: '',
      profile_guide_length: 0,
      profiles_vertical_length: 0
    },
    sashes_count: NaN,
  }

  const dispatch = useAppDispatch();
  const openedConstructionId = useGetOpenedConstructionId();
  const openedConstruction = useGetOpenedConstruction();
  const estimate = useConstructionEstamate();

  const [newConstruction, setNewConstruction] = useState<IReceivedConstructions>(initStatePage);

  const [isEdited, setIsEdited] = useState(false);
  const enabledButton = (
    // obj: IReceivedConstructions
  ): boolean => {
    return true;
    // return Object.values(obj).every((field) => {
    //   if (typeof field === 'object' && !Array.isArray(field) && field !== null) {
    //     return enabledButton(field);
    //   }
    //   if (!isNaN(parseInt(field))) {
    //     return !isNaN(field);
    //   }
    //   return field !== '';
    // });
  };

  const handleChange = (e: any) => {
    setIsEdited(true);
    const { name, value } = e.target;
    setNewConstruction((prevState) => {
      return {...prevState, [name]: value}
    })
  }

  const handleChangePark = (e: any) => {
    setIsEdited(true);
    const { name, value } = e.target;

    setNewConstruction(prevState => {
      const newState = JSON.parse(JSON.stringify(prevState));
      if (name == 'parking_right_sashes_count' || name == 'parking_left_sashes_count') {
        newState.sashes_count = newState.sashes_count ? newState.sashes_count + parseInt(value) : parseInt(value)
      }

      if (name == 'slider') {
        newState.parking.parking_left_sashes_count = parseInt(value);
        newState.parking.parking_right_sashes_count = newState.sashes_count - parseInt(value);

        return ({
          ...newState
        })
      }

      return ({
        ...newState,
        parking: {
          ...newState.parking,
          [name]: value
        }
      })
    });
  };


  const handleChangeParkSashCount = (e: any) => {
    setIsEdited(true);
    const { value } = e;

    setNewConstruction(prevState => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.parking.parking_left_sashes_count = parseInt(value);
      newState.parking.parking_right_sashes_count = newState.sashes_count - parseInt(value);

      return ({
        ...newState
      })
    });
  };

  const handleChangeProfiles = (e: any) => {
    setIsEdited(true);
    const { name, value } = e.target;
    setNewConstruction(prevState => ({
      ...prevState,
      profiles: {
        ...prevState.profiles,
        [name]: value
      }
    }));
  };

  const handleChangeAssembly = (e: any) => {
    setIsEdited(true);
    const { value } = e.target;
    setNewConstruction(prevState => ({
      ...prevState,
      assembly: value
    }));
  };

  const handleChangeColor = (e: any) => {
    setIsEdited(true);
    const { value } = e.target;
    setNewConstruction(prevState => ({
      ...prevState,
      painting: value
    }));
  };

  const createSaveConstruction = (prop: IReceivedConstructions) => {
    const construction = JSON.parse(JSON.stringify(prop));

    // delete construction.sashes;
    construction.sashes_count =
      (construction.parking.parking_left_sashes_count ? parseInt(construction.parking.parking_left_sashes_count) : 0)
      + (construction.parking.parking_right_sashes_count ? parseInt(construction.parking.parking_right_sashes_count) : 0)
    setIsEdited(false);
    if (params.id === 'new') {
      dispatch(createConstructionAction(construction));
    } else {
      dispatch(putConstructionAction(construction));
    }
  }

  const changeSashData = (data: ISash) => {
    const selectedSasheIndex = newConstruction.sashes?.findIndex((item) => data.sash_number === item.sash_number);
    if (selectedSasheIndex !== undefined && selectedSasheIndex >= 0 && newConstruction.sashes) {
      const newState: IReceivedConstructions = JSON.parse(JSON.stringify(newConstruction));
      if (newState.sashes) {
        newState.sashes[selectedSasheIndex] = data;

      }
      if (isEdited) {
        dispatch(calculateConstructionAction(newState))
        setIsEdited(false);
      }
    }
  }

  const replaceSashes = (sashesList: ISash[]) => {
    setIsEdited(true);
    setNewConstruction((prevState) => {
      const newCunt = (sashesList.length - (prevState.sashes?.length ? prevState.sashes?.length : 0));
      const newPark = {
        ...prevState.parking,
      }
      if (newPark.parking_left_type && newPark.parking_left_sashes_count) {
        newPark.parking_left_sashes_count = newPark.parking_left_sashes_count + newCunt;
      } else if (newPark.parking_right_type && newPark.parking_right_sashes_count) {
        newPark.parking_right_sashes_count = newPark.parking_right_sashes_count + newCunt;
      }
      return {
        ...prevState,
        parking: newPark,
        sashes: sashesList,
        sashes_count: parseInt(`${newPark.parking_left_sashes_count ? newPark.parking_left_sashes_count : 0}`) + parseInt(`${newPark.parking_right_sashes_count ? newPark.parking_right_sashes_count : 0}`)
      }
    });
  }

  useEffect(() => {
    if (openedConstructionId || (params.id && params.id !== 'new')) {
      if (params.id !== 'new') {
        dispatch(fetchEstimatesAction(parseInt(params.id)));
      }
      dispatch(fetchConstructionByIdAction({id: openedConstructionId ? openedConstructionId :parseInt(params.id) }));
    }
    if (openedConstructionId && params.id === 'new') {
      history.push(Order_Construction.replace(':id', `${openedConstructionId}`).replace(':orderId', params.orderId));
    }
  }, [openedConstructionId]);

  useEffect(() => {
    if (openedConstruction) {
      setNewConstruction(openedConstruction);
    }
  }, [openedConstruction]);

  const recalculateEstimate = () => {
    dispatch(fetchEstimatesAction(parseInt(params.id)));
  }

  return (
    <>
      <div className="content-section">
        <ConstructionForm
          createConstruction={() => createSaveConstruction(newConstruction)}
          formObject={newConstruction}
          isNew={params.id === 'new'}
          handleChange={handleChange}
          enabledButton={enabledButton}
          handleChangeAssembly={handleChangeAssembly}
          handleChangeColor={handleChangeColor}
          handleChangePark={handleChangePark}
          handleChangeParkSashCount={handleChangeParkSashCount}
          handleChangeProfiles={handleChangeProfiles}
          isEdited={isEdited}
          disabled={disabled}
          key={1}
        />
        <GraphicForm
          setIsEdited={setIsEdited}
          replaceSashes={replaceSashes}
          changeSashData={changeSashData}
          formObject={newConstruction}
          key={12}
        />

        <Button
          className={'construction__button'}
          disabled={disabled ? disabled : !enabledButton()}
          onClick={() => createSaveConstruction(newConstruction)}>
          Сохранить
        </Button>
        {
          estimate?.length ? (
            <EstimateTable recalculateEstimate={recalculateEstimate} items={estimate} id={params.id} />
          ) : null
        }
      </div>
    </>
  );
};

export default ConstructionPage;
