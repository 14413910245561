import {IReceivedConstructions, ISash} from "../store/order/order.types";

export const decodeJwt = (token: string) => {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid JWT token');
  }

  const payload = parts[1];
  const decodedPayload = atob(payload);

  return JSON.parse(decodedPayload);
}

export const reCalculate = (sashes: ISash[], formObject: IReceivedConstructions) => {
  sashes = JSON.parse(JSON.stringify(sashes));
  const fixedSashes = sashes.reduce((acc, item) => {
    if (item.width) {
      acc++;
    }
    return acc;
  }, 0);

  const blockedSpaceToChangeSashe = sashes.reduce((acc, item) => {
    if (item.width) {
      acc += parseFloat(item.width);
      return acc;
    }
    return acc
  }, 0);

  const gapW = sashes.reduce((acc, item, index, arr) => {
    const gap = arr[index + 1] ? calculateGap(item, arr[index + 1]) : 0;
    acc += gap
    return acc;
  }, 0)

  const freeSpace = formObject.width - blockedSpaceToChangeSashe - gapW - outerGap(sashes[0], formObject.profiles.profile_start_type) - outerGap(sashes[sashes.length - 1], formObject.profiles.profile_end_type);

  // const deReadOnlySashes: ISash[] = sashes

  const newList = sashes.map((item, _, arr) => {
    if (item.width) {
      return item;
    }
    item.width_calculated = `${((freeSpace / (arr.length - fixedSashes))).toFixed(1)}`;
    return item;
  })
  return newList;
}



export const outerGap = (sash:ISash, profileType: any) => {
  // #расстояние по краям (с учетом профилей), мм
  const GAP_EDGE_PORCH_PORCH = 35;
  const GAP_EDGE_MOTHER_FATHER = 37;
  const GAP_EDGE_FATHER_FATHER = 36;


  if (profileType == 'porch' && sash.profile_type_left === 'porch') {
    return GAP_EDGE_PORCH_PORCH;
  }

  if (profileType === 'father' || sash.profile_type_left === 'father') {
    if (profileType === 'mother' || sash.profile_type_left === 'mother') {
      return GAP_EDGE_MOTHER_FATHER
    } else {
      return GAP_EDGE_FATHER_FATHER
    }
  }
  return 0;
}

export const calculateGap = (sash: ISash, nextSash: ISash) => {
  const GAP_BETWEEN_PORCH_PORCH = 18;
  const GAP_BETWEEN_MOTHER_FATHER = 20;
  const GAP_BETWEEN_FATHER_FATHER = 22;
  if (sash.profile_type_right === 'porch' && nextSash.profile_type_left === 'porch') {
    return GAP_BETWEEN_PORCH_PORCH;
  }
  if (sash.profile_type_right === 'father') {
    if (nextSash.profile_type_left === 'father') {
      return GAP_BETWEEN_FATHER_FATHER;
    }
  }
  if (
    (sash.profile_type_right === 'father' && nextSash.profile_type_left === 'mother')
    || (sash.profile_type_right === 'mother' && nextSash.profile_type_left === 'father')) {
    return GAP_BETWEEN_MOTHER_FATHER;
  }
  console.log('12342134123123');
  return 0;
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}
