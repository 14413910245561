import { useAppSelector } from 'src/store/hooks';

const useGetUser = () =>
  useAppSelector(({ user: { user } }) => user);

const useUserRole = () =>
  useAppSelector(({ user: { user: {role} } }) => role);
const useUserLoading = () =>
  useAppSelector(({ user: { loading } }) => loading);

const useUserError = () =>
  useAppSelector(({ user: { err } }) => err);
const useRegisterDataDealerId = () =>
  useAppSelector((state) => state?.user.registerDataDealerId);
const useUserToken = () =>
  useAppSelector(({ user: { access_token } }) => access_token);

export {
  useUserError,
  useUserLoading,
  useGetUser,
  useRegisterDataDealerId,
  useUserToken,
  useUserRole,
};
