import axios from 'axios';
import {store} from "../store";
// import {logout} from "../store/user/user.slice";
import {fetchLogoutAction} from "../store/user/user.actions";
import * as process from "process";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

axiosInstance.interceptors.request.use((req) => {
  const token = localStorage.getItem('access_token');
  req.headers = {
    'Content-Type': 'application/json',
    'Authorization': token ?`JWT ${token}` : undefined,
  }
  return req;
})

axiosInstance.interceptors.response.use((res) => {
  return res;
}, (e) => {
  if (e.message.includes('401')) {
    // @ts-ignore
    store.dispatch(fetchLogoutAction());

  }
  return Promise.reject(e);
})
export { axiosInstance };
