import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import AppRouter from './AppRouter';
import Footer from './pages/components/footer/Footer';
import Header from './pages/components/header/Header';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primeicons/primeicons.css';
import './App.css';
import {Provider} from 'react-redux';
import {store} from './store';
import BreadCrumbsComponent from "./pages/components/breadCrumbs/BreadCrumbsComponent";

function App() {
  return (
    <Provider store={store}>
      <div className={'app'}>
        <BrowserRouter>
          <Header />
          <BreadCrumbsComponent />
          <div className={'app-container'}>
            <AppRouter />
          </div>
        </BrowserRouter>

        <Footer />
      </div>
    </Provider>
  );
}

export default App;
